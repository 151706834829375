$theme: ('theme-heavyitalic-font': ('family': 'brother-1816', 'weight': 700, 'style': 'italic'), 'theme-semibold-font': ('family': 'brother-1816', 'weight': 600, 'style': 'normal'), 'theme-heavy-font': ('family': 'brother-1816', 'weight': 700, 'style': 'normal'), 'theme-semibolditalic-font': ('family': 'brother-1816', 'weight': 600, 'style': 'italic'), 'theme-regular-font': ('family': 'brother-1816', 'weight': 300, 'style': 'normal'), 'theme-regular-italic-font': ('family': 'brother-1816', 'weight': 300, 'style': 'italic'), 'theme-logotype-max-width': 180px, 'theme-logotype-minimized-width': 125px, 'theme-logotype-mobile-width': 55px, 'theme-intro-background-color': #7E0E0E, 'theme-intro-background-image': '', 'theme-intro-books-author-color': #ED6E6D, 'theme-intro-bonnier-text-color': #ED6E6D, 'theme-intro-main-text-color': #F2F2F2, 'theme-intro-search-text-color': #F2F2F2, 'theme-intro-search-icon': '../img/icon-magnifier-white-small.svg', 'theme-menu-bg': #F2F2F2, 'theme-menu-bar-bg': #7E0E0E, 'theme-hamburger-color': #F2F2F2, 'theme-news-background-color': #FFFFFF, 'theme-news-arrow-color': '../img/icon-arrow-right-fenix.svg', 'theme-news-title-color': #343E42, 'theme-news-section-title-color': #ED6E6D, 'theme-books-section-title': #ED6E6D, 'theme-authors-section-title': #F2F2F2, 'theme-authors-bg-color': #343E42, 'theme-themes-title-color': #F2F2F2, 'theme-contact-puff-bg': #125D70, 'theme-contact-puff-color': #F2F2F2, 'theme-qa-color': #343E42, 'theme-site-standard-color': #343E42, 'theme-button-bg-color': #343E42, 'theme-button-text-color': white, 'theme-article-image-shadow-color': #343E42, 'theme-login-icon': '../img/icon-login.svg', 'theme-cart-icon': '../img/icon-cart.svg', 'theme-account-icon': '../img/icon-account.svg');body {
      padding-top: 0 !important;
}

html {
      margin-top: 0 !important;
}

.menu-bar {
      position: relative;

      .site-menu__login,
      .site-menu__hamburger {
            display: none !important;
      }
}

.article {
      &__breadcrumbs,
      &__files,
      &__share,
      &__tags{
            display: none;
      }

      &__content {
            border-bottom: 0;
      }
}
.footer {
      display: none;
}